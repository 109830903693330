import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';
import '../stylesheets/shares.scss'

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "form" ]

  connect () {
    StimulusReflex.register(this)
  }
  beforeReflex(anchorElement) {
    this.hideAllFloatedElements()
  }
  showBoardShares (event) {
    if(!super.isConnected(event)) { return false; }

    this.clearModalContent()

    if(!event.target.dataset['board-id']) {
      var etarget = this.getParent(event.target, 'button', 'tagname')
    } else {
      var etarget = event.target
    }

    this.getModalController().show(event)
    this.getModalController().hideFooter()

    this.stimulate('BoardsharesReflex#showBoardShares')
  }
  newBoardShare (event) {
    if(!super.isConnected(event)) { return false; }
    this.stimulate('BoardsharesReflex#newBoardShare', this.getParent(event.target, 'button', 'tagname'))
  }
  editBoardShare (event) {
    if(!super.isConnected(event)) { return false; }
    this.stimulate('BoardsharesReflex#editBoardShare', this.getParent(event.target, 'button', 'tagname'))
  }
  createShare (event) {
    if(!super.isConnected(event)) { return false; }
    this.stimulate('BoardsharesReflex#createShare', event.target, this.getFormData())
  }
  updateShare (event) {
    if(!super.isConnected(event)) { return false; }
    this.stimulate('BoardsharesReflex#updateShare', event.target, this.getFormData())
  }
  destroyShare (event) {
    const ok = confirm("Are you sure you would like to delete this share?")
    if(!super.isConnected(event)) { return false; }
    if(ok) {
      this.stimulate('BoardsharesReflex#destroyShare', this.getParent(event.target, 'button', 'tagname'))
    }
  }
  afterReflex(anchorElement) {
    this.hideAllFloatedElements()
    if (anchorElement == undefined) {
      return false
    }
  }
  async validateFormToken(event) {
    console.log("validate form token " + event.target.value)
    if (super.isConnected(event)) {
      return this.stimulate('BoardsharesReflex#validateFormToken', event.target)
    } else {
      const response = await this.doFetch(
          '/shares/validate',
          'POST',
          JSON.stringify({
            authenticity_token: this.getCsrfToken(),
            remote: true,
            token: event.target.value
          }),
      );
      if (response.ok) {
        let errors = await response.json();
        if(errors.token && errors.token.length > 0) {
          document.getElementById("share-form-alerts").innerHTML = "<div class=\"alert alert-danger\">" + errors.token.join("<br>") + "</div>";
          document.querySelector("input[type=\"submit\"]").disabled = true
        } else {
          document.querySelector("input[type=\"submit\"]").disabled = false
          // document.getElementById("share-form-alerts").innerHTML = "<div class=\"alert alert-success\">✅</div>";
        }
      }
    }
  }
  getFormData () {
    let formData = new FormData(this.formTarget)
    const data = {}
    formData.forEach((value, key) => (data[key] = value));
    return data
  }
}
