import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "board", "addform" ]

  connect () {
    // StimulusReflex.register(this)

    if(this.controller_id == undefined) {
      this.controller_id = Math.random() * 1000000
    }
    // console.log("connecting boards_controller.js " + this.controller_id)
    // console.trace()

    if(window.didInitializeBoardEventListeners == undefined) {
      // console.log("setup EventListeners")
      document.addEventListener("didAddCard", this.didAddCard.bind(this))
      document.addEventListener("didLikeCard", this.didLikeCard.bind(this))
      document.addEventListener("didMoveCard", this.didMoveCard.bind(this))
      document.addEventListener("didStartEditingCard", this.didStartEditingCard.bind(this))
      document.addEventListener("didCancelEditingCard", this.didCancelEditingCard.bind(this))
      document.addEventListener("didUpdateCard", this.didUpdateCard.bind(this))
      document.addEventListener("didDuplicateCard", this.didDuplicateCard.bind(this))
      document.addEventListener("didDeleteCard", this.didDeleteCard.bind(this))

      document.addEventListener("didUpdateColumn", this.didUpdateColumn.bind(this))
      document.addEventListener("didAddColumn", this.didAddColumn.bind(this))
      document.addEventListener("didDeleteColumn", this.didDeleteColumn.bind(this))

      window.didInitializeBoardEventListeners = true
    }

    this.cardDragOverCounter = 0
    this.cardDragOverId = 0
    this.cardDragOverElement = null
  }

  getBoardsStimulusController () {
    return document.querySelector("#board-workspace").boardsstimulus
  }

  afterReflex(anchorElement) {
    this.hideAllFloatedElements()
    if (anchorElement == undefined) {
      return false
    }
    const action = anchorElement.dataset['action']
    this.getBoardsStimulusController().setLinkDefaultTarget()
  }

  didAddCard (event) {
    event.stopPropagation();
    event.preventDefault();

    const { cardId, cardPriority, cardHtml, columnId } = event.detail
    
    if(this.cardElementForId(cardId) != null) {
      return true;
    }
    let column = document.querySelector(`#column-list-${columnId}`);

    column.insertAdjacentHTML("afterbegin", cardHtml)

    this.sortColumn(column)

    // this.getModalController().close()
  }
  didLikeCard (event) {
    event.stopPropagation();
    event.preventDefault();
    
    const { cardId } = event.detail

    console.log("didLikeCard ");

    let card = this.cardElementForId(cardId)
    let likesCounter = document.querySelector(`#post-${cardId}-likes`)

    likesCounter.innerText = Number(likesCounter.innerText) + 1
  }
  didMoveCard (event) {
    const { cardId, targetColumnId, newPriority, isTopTarget } = event.detail

    // console.log("didMoveCard");

    let moveCard      = this.cardElementForId(cardId)
    let targetColumn  = document.querySelector(`#column-list-${targetColumnId}`)
    let srcColumn     = this.getParent(moveCard, "posts")

    if(isTopTarget) {
      targetColumn.insertBefore(moveCard, targetColumn.children[(newPriority - 1)])
    } else {
      targetColumn.insertBefore(moveCard, targetColumn.children[(newPriority)])
    }
    this.sortColumn(srcColumn)
    this.sortColumn(targetColumn)
  }
  didStartEditingCard (event) {
    event.stopPropagation();
    event.preventDefault();

    const { cardId } = event.detail
    // console.log("didStartEditingCard");

    let callbackIndex = this.getModalController().closeEventHandlers.length

    this.toggleCardLock(cardId, true)

    this.getModalController().closeEventHandlers.push(function () {
      if(this.getBoardsStimulusController() != undefined) {
        this.getBoardsStimulusController().stimulate('BoardsReflex#didCancelEditing', this.getModalController().closeButtonTarget, cardId)
      }

      this.getModalController().closeEventHandlers.splice(callbackIndex, 1)
    }.bind(this, cardId, callbackIndex))
  }
  didCancelEditingCard (event) {
    const { cardId } = event.detail
    this.toggleCardLock(cardId)
  }
  toggleCardLock (cardId, locked) {
    let lockElements     = document.querySelectorAll(`${this.cardHtmlIdForId(cardId)} .post-header .card-locked`)
    let unlockElements   = document.querySelectorAll(`${this.cardHtmlIdForId(cardId)} .post-header .card-unlocked`)

    if(locked) {
      this.removeClass(lockElements, "d-none")
      this.addClass(unlockElements, "d-none")
    } else {
      this.addClass(lockElements, "d-none")
      this.removeClass(unlockElements, "d-none")
    }
  }
  addClass (elements, tclass="d-none") {
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add(tclass)
    }
  }
  removeClass (elements, tclass="d-none") {
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove(tclass)
    }
  }
  
  didUpdateCard (event) {
    const { cardId, cardHtml } = event.detail
    this.cardElementForId(cardId).replaceWith(super.convertStringToHTML(cardHtml))
    this.toggleCardLock(cardId)
  }

  didDuplicateCard (event) {
    const { srcCardId, newCardId, targetColumnId } = event.detail

    let targetColumn  = document.querySelector(`#column-list-${targetColumnId}`)
    let srcCard      = this.cardElementForId(srcCardId)
    var newCard      = srcCard.cloneNode(true)
    var newCardHtml  = srcCard.innerHTML // .cloneNode(true)

    newCardHtml = newCardHtml.replace(srcCardId, newCardId)

    newCard.innerHTML = newCardHtml
    srcCard.insertAdjacentElement("afterend", newCard)
    this.sortColumn(targetColumn)
  }

  didDeleteCard (event) {
    const { cardId, columnId } = event.detail
    this.cardElementForId(cardId).remove()
    let column = document.querySelector(`#column-${columnId}`)
    this.sortColumn(column)
  }
  didUpdateColumn (event) {
    const { columnId, title, color, colstatus } = event.detail
    let column = document.querySelector(`#column-${columnId}`)
    let boardContainer = column.parentElement

    column.querySelector(".boardcolumn-title").innerText = title

    if(colstatus < boardContainer.children.length) {
      boardContainer.insertBefore(column, boardContainer.children[(colstatus - 1)])
    }
    if(colstatus >= boardContainer.children.length) {
      boardContainer.children[colstatus - 1].insertAdjacentElement("afterend", column)
    }

    column.style.borderColor = color;
    this.getModalController().close()
  }
  didAddColumn (event) {
    const { columnId, columnHtml, columnStatus, boardId } = event.detail
    // console.log("didAddColumn")
    let boardContainer = document.querySelector("#boardcontainer" + boardId)
    boardContainer.insertAdjacentHTML("beforeend", columnHtml)
  }

  didDeleteColumn (event) {
    const { columnId } = event.detail
    document.querySelector(`#column-${columnId}`).remove()
  }

  sortColumn (boardColumn) {
    let priorityBadges = boardColumn.querySelectorAll("li[data-cardid]");
    var counter = 0;
    for (var i = 0; i < priorityBadges.length; i++) {
      if(priorityBadges[i] !== undefined) {
        priorityBadges[i].querySelector("[data-priority]").dataset["priority"] = counter + 1;
        priorityBadges[i].querySelector(".post-header .bg-primary").innerText = counter + 1;
        counter++;
      }
    }
  }
  cardHtmlIdForId(pid) {
    return `post_${pid}`;
  }
  cardElementForId(pid) {
    return document.getElementById(this.cardHtmlIdForId(pid))
  }
}
