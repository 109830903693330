import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

export default class extends ApplicationController {
  static targets = [
    "select",
    "sortable",
    "item",
    "category",
    "orderbutton",
    "filterinput",
  ];

  connect() {
    super.connect();
    StimulusReflex.register(this);

    this.order = super.getCookie("sortorder");
    this.orderBy = super.getCookie("sortOrderBy");

    if (!this.order) {
      super.setCookie("sortorder", 1);
      this.order = 1;
    }
    if (!this.orderBy) {
      super.setCookie("sortOrderBy", this.selectTarget.value);
      this.orderBy = this.selectTarget.value;
    } else {
      this.selectTarget.value = this.orderBy;
    }
    this.selectTarget.addEventListener("change", this.sort.bind(this));
    this.orderbuttonTarget.addEventListener(
      "click",
      this.toggleOrder.bind(this),
    );

    this.updateOrderButtonIcon();
    this.sort();

    document.cookie = "sortorder=" + this.order;
  }

  sort() {
    var tb = this.sortableTarget,
      tr = this.itemTargets, // put rows into array
      i;

    this.orderBy = this.selectTarget.value;
    super.setCookie("sortOrderBy", this.orderBy);

    var reverse = this.order;
    // reverse = -((+reverse) || -1);
    tr = tr.sort(
      function (a, b) {
        // sort rows
        let compa = a.querySelector(
            '[data-sorter-category="' + this.orderBy + '"]',
          ).dataset.sorterValue,
          compb = b.querySelector(
            '[data-sorter-category="' + this.orderBy + '"]',
          ).dataset.sorterValue;

        if (compa != undefined) {
          return reverse * compa.localeCompare(compb);
        }
        return (
          reverse *
          a
            .querySelector('[data-sorter-category="' + this.orderBy + '"]')
            .textContent.trim()
            .localeCompare(
              b
                .querySelector('[data-sorter-category="' + this.orderBy + '"]')
                .textContent.trim(),
            )
        );
      }.bind(this),
    );
    for (i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
  }

  toggleOrder() {
    this.order = this.order * -1;
    super.setCookie("sortorder", this.order);
    this.updateOrderButtonIcon();
    this.sort();
  }
  updateOrderButtonIcon() {
    this.orderbuttonTarget.innerHTML =
      this.order == 1
        ? '<i class="bi bi-arrow-down-square"></i>'
        : '<i class="bi bi-arrow-up-square"></i>';
    this.orderbuttonTarget.dataset.title =
      this.order == 1 ? "Aufsteigend sortiert" : "Absteigend sortiert";
  }

  async filter(e) {
    console.log("filter by: " + e.target.value);

    let filterValue = e.target.value.toUpperCase();
    var counter = 0;

    this.itemTargets.forEach(function (el, key, listObj) {
      let txtValue = el.textContent || el.innerText;
      // console.log(`Element ${el}:  ${txtValue} \n`);
      if (txtValue.toUpperCase().indexOf(filterValue) > -1) {
        el.style.display = "";
        counter++;
      } else {
        el.style.display = "none";
      }
    }, "arg");

    let headers = {
      "Content-Type": "application/json",
      Accept: "text/html",
      "X-Requested-With": "XMLHttpRequest",
      authenticity_token: this.getCsrfToken(),
    };

    let res = await super.doFetch(
      "/board_containers/search",
      "POST",
      JSON.stringify({
        authenticity_token: this.getCsrfToken(),
        filterValue: e.target.value,
      }),
      headers,
    );
    if (res.ok) {
      let html_text = await res.text();
      console.log("html: " + html_text);
      this.sortableTarget.innerHTML = html_text;
    }
  }
}
