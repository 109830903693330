import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';

// import 'easymde/dist/easymde.min.css'

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = [ "templateselect", "preview"]

  connect () {
    StimulusReflex.register(this)

    this.templateSelect = document.querySelector("#template_name");
    if(this.templateSelect) {
      console.log("setup change function")
      this.templateselectTarget.addEventListener('change', this.didChangeTemplate.bind(this))
    } else {
      console.log("no template select found")
    }
  }

  async didChangeTemplate (event) {
    console.log("didChangeTemplate to " + this.templateselectTarget.value)

    const htmldata = await this.loadHtml();
    document.querySelector('iframe').srcdoc = htmldata.html
    return false; 
  }

  exportHtml (event) {
    event.preventDefault();
    console.log("push HTML download");
    this.pushDownload();
  }

  async pushDownload () {
    const htmldata = await this.loadHtml();
    var blob = new Blob([htmldata.html], {type: "text/plain;charset=utf-8"});
    await saveAs(blob, htmldata.title + ".html");
  }

  async loadHtml () {
    let boardid = this.element.dataset.boardId;
    let csrf_tag = document.querySelector('meta[name="csrf-token"]');
    let url = "/boards/" + boardid + "/html";
    let method = "POST";

    let raw_body = {
      templatename: this.templateselectTarget.value,
      remote: true
    }
    let headers = {
      "Content-Type": "application/json",
      // "Accept": "text/html",
      'X-Requested-With': 'XMLHttpRequest'
    }
    
    if(csrf_tag != undefined && csrf_tag.content != undefined) {
      let csrf_token = document.querySelector('meta[name="csrf-token"]').content
      raw_body.authenticity_token = csrf_token
      headers['authenticity_token'] = csrf_token
    }
    let body = JSON.stringify(raw_body);

    const response = await this.doFetch (url, method, body, headers); 
    const raw_string = await response.text();
    const html = this.htmlDecode(raw_string)
    var filename = "standardfilename";

    if(html.match(/(?:<h1.*>)(.*)<\/h1>/m)) {
      filename = html.match(/(?:<h1.*>)(.*)<\/h1>/m)[1];
    }

    let htmldata = {
      "title": filename.trim(),
      "html": html
    };
    return htmldata
  }
  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
}
