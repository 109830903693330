import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";
import interact from "interactjs";

/* good article: https://javascript.info/mouse-drag-and-drop */
export default class extends ApplicationController {
  connect() {
    super.connect();
    StimulusReflex.register(this);

    this.boardsstimulusController =
      document.querySelector("#board-workspace").boardsstimulus;

    interact('li.post-item [draggable="true"]').draggable({
      listeners: {
        start: this.dragCardStart.bind(this),
        move: this.dragCard.bind(this),
      },
    });

    interact("li.post-item").dropzone({
      ondrop: function (event) {
        console.log(
          event.relatedTarget.id + " was dropped into " + event.target.id,
        );

        this.stimulateDropOnCard(event, event.target);
        this.dragCardEnd(event);
      }.bind(this),
    });

    interact(".board-column").dropzone({
      ondrop: function (event) {
        console.log(
          event.relatedTarget.id + " was dropped into " + event.target.id,
        );
        this.stimulateDropOnColumn(event, event.target);
        this.dragCardEnd(event);
      }.bind(this),
    });

    this.altModifierKeyPressed = false;

    document.addEventListener(
      "keydown",
      function (event) {
        if (event.keyCode == 18) {
          this.altModifierKeyPressed = true;
        }
      }.bind(this),
    );
    document.addEventListener(
      "keyup",
      function (event) {
        if (event.keyCode == 18) {
          this.altModifierKeyPressed = false;
        }
      }.bind(this),
    );
  }
  getBoardsStimulusController() {
    return document.querySelector("#board-workspace").boardsstimulus;
  }

  voidDrag(event) {
    return false;
  }
  dragCardStart(event) {
    event.stopPropagation();
    event.preventDefault();

    this.dragItem = this.getParent(event.target, "cardid", "dataset");
    // console.log("dragCardStart " + this.dragItem.dataset.cardid)

    this.formatDragCard(this.dragItem);

    return true;
  }
  dragCard(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.dragItem == undefined) {
      // console.log("this.dragItem is undefined")
      return false;
    }
    this.moveDragTwin(event);
    this.highlightCardDragOver(event);
  }
  dragCardEnd(event) {
    event.stopPropagation();
    event.preventDefault();
    let coord = this.coordFromEvent(event);

    this.removeFormatDragCard(this.dragItem);
    this.dragItem = undefined;
  }
  insertDragTwin(draggedCard) {
    // console.log("create dragTwin with id: " + draggedCard.dataset.cardid)
    this.dragTwin = draggedCard.cloneNode(true);
    this.dragTwin.style.width =
      draggedCard.getBoundingClientRect().width + "px";
    this.dragTwin.style.position = "absolute";
    draggedCard.insertAdjacentElement("afterend", this.dragTwin);
  }
  formatDragCard(draggedCard) {
    draggedCard.classList.add("opacity-50");
    if (
      this.dragTwin != undefined &&
      this.dragTwin.dataset.cardid != draggedCard.dataset.cardid
    ) {
      this.insertDragTwin(draggedCard);
    }
  }
  moveDragTwin(event) {
    this.dragCoordinates = this.coordFromEvent(event);
    this.dragCoordinates = [
      this.dragCoordinates[0] - 125,
      this.dragCoordinates[1] - 25,
    ];

    if (this.dragTwin == undefined) {
      this.insertDragTwin(this.dragItem);
    }

    this.dragTwin.style.left = this.dragCoordinates[0] - 125 + "px";
    this.dragTwin.style.top = this.dragCoordinates[1] - 25 + "px";
  }
  removeFormatDragCard(draggedCard) {
    if (draggedCard != undefined) {
      draggedCard.style.position = "static";
      draggedCard.classList.remove("opacity-50");
    }
    this.dragTwin.remove();
  }

  async stimulateDropOnCard(event, dropOnCard) {
    console.log("stimulateDropOnCard");
    // Reflex method:
    // receiveDrop(cardTargetId, new_status, cardid, isTopTarget, copy=false)

    let col = this.getParent(dropOnCard, "board-column").dataset.status;
    let currentDropzone = dropOnCard.querySelector(".card-dropzone");

    if (super.isConnected()) {
      this.getBoardsStimulusController().stimulate(
        "BoardsReflex#receiveDrop",
        event.target,
        dropOnCard.dataset.cardid, // dropTargetId
        col,
        this.dragItem.dataset.cardid,
        this.isTopTargetEvent(event, currentDropzone), // isTopTarget
        this.altModifierKeyPressed,
      );
    } else {
      console.log("no stimulateDrop");

      const response = await this.doFetch(
        "/posts/" + this.dragItem.dataset.cardid + "/move",
        "PATCH",
        JSON.stringify({
          authenticity_token: this.getCsrfToken(),
          dropTarget: dropOnCard.dataset.cardid,
          isTopTarget: this.isTopTargetEvent(event, currentDropzone),
          board_column_id: col,
          remote: true,
        }),
      );
      if (response.ok) {
        location.reload();
        /*
         * use this for further customisation - for now go with reloading the page
        let json_return = await response.json();
        console.log("did move with response: " + JSON.stringify(json_return))
        */
      } else {
        alert("There was an error when trying to move this card!");
      }
    }
  }

  async stimulateDropOnColumn(event, dropOnColumn) {
    console.log("stimulateDropOnColumn");
    // Reflex method:
    // receiveDrop(cardTargetId, new_status, cardid, isTopTarget, copy=false)

    let col = dropOnColumn.dataset.status;

    if (super.isConnected()) {
      this.getBoardsStimulusController().stimulate(
        "BoardsReflex#receiveDrop",
        event.target,
        -1, // dropTargetId
        col,
        this.dragItem.dataset.cardid,
        this.isTopColumnEvent(event, dropOnColumn), // isTopTarget
        this.altModifierKeyPressed,
      );
    } else {
      console.log("no stimulateDrop");

      const response = await this.doFetch(
        "/posts/" + this.dragItem.dataset.cardid + "/move",
        "PATCH",
        JSON.stringify({
          authenticity_token: this.getCsrfToken(),
          dropTarget: -1,
          isTopTarget: this.isTopColumnEvent(event, dropOnColumn),
          board_column_id: col,
          remote: true,
        }),
      );
      if (response.ok) {
        location.reload();
        /*
         * use this for further customisation - for now go with reloading the page
        let json_return = await response.json();
        console.log("did move with response: " + JSON.stringify(json_return))
        */
      } else {
        alert("There was an error when trying to move this card!");
      }
    }
  }

  highlightCardDragOver(event) {
    let dragTargetCard = this.searchValidDropTarget(event);
    if (!dragTargetCard || dragTargetCard == undefined) {
      return false;
    }
    // console.log("highlightCardDragOver found dragTargetCard " + dragTargetCard.dataset.cardid)
    let targetDropzone = dragTargetCard.querySelector(".card-dropzone");

    // return if no card was found
    if (!targetDropzone) {
      return false;
    }
    // console.log("\tfound dropzone")
    this.cardDragOver(dragTargetCard);
  }

  removeAllDragoverHighlights() {
    document.querySelectorAll(".card-dropzone").forEach(
      function (cdrz) {
        this.removeClasses(cdrz, [
          "pt-2",
          "border-top",
          "pb-2",
          "border-bottom",
          "border-5",
        ]);
      }.bind(this),
    );
  }
  cardDragOver(dragTargetCard) {
    // if the drag occured over a new card
    // set this.cardDragOverElement
    if (
      this.cardDragOverElement == undefined ||
      dragTargetCard.id != this.cardDragOverElement.id
    ) {
      if (dragTargetCard.id != this.dragItem.id) {
        this.cardDragOverElement = dragTargetCard;
      }
    }

    this.removeAllDragoverHighlights();

    // Add margin top or bottom depending on event coordinates
    if (this.cardDragOverElement == undefined) {
      return true;
    }
    let currentDropzone =
      this.cardDragOverElement.querySelector(".card-dropzone");
    if (this.isTopTargetEvent(event, currentDropzone)) {
      this.addClasses(currentDropzone, ["pt-2", "border-5", "border-top"]);
    } else {
      this.addClasses(currentDropzone, ["pb-2", "border-5", "border-bottom"]);
    }
  }

  isTopColumnEvent(event, column) {
    let columnCards = column.querySelectorAll("li.post-item");

    // if there are no cards in the column return false
    if (columnCards == undefined || columnCards.length == 0) {
      return false;
    }

    if (this.isTopTargetEvent(event, columnCards[0])) {
      return { dropTarget: columnCards[0].dataset.cardid, isTopTarget: true };
    }
    if (!this.isTopTargetEvent(event, columnCards[columnCards.length - 1])) {
      return {
        dropTarget: columnCards[columnCards.length - 1].dataset.cardid,
        isTopTarget: false,
      };
    }
    return false;
  }

  isTopTargetCoords(coords, trg, debug = false) {
    if (trg == undefined || trg === false) {
      return true;
    }

    let trgTop = trg.getBoundingClientRect().top;
    let trgHeight = trg.getBoundingClientRect().height;
    let border = trgTop + trgHeight / 2;

    if (debug) {
      console.log(
        "isTopTargetCoords: event: y:" +
          coords[1] +
          " trg: y + h : (" +
          trgTop +
          " + " +
          trgHeight +
          ") / 2 = " +
          border,
      );
      if (coords[1] <= border) {
        console.log("isTopTarget true");
      } else {
        console.log("isTopTarget false");
      }
    }
    return coords[1] <= border;
  }

  isTopTargetEvent(event, trg) {
    return this.isTopTargetCoords(this.coordFromEvent(event), trg);
  }

  coordFromEvent(event) {
    if (event.type.match(/touch/)) {
      return [event.changedTouches[0].pageX, event.changedTouches[0].pageY];
    }
    if (event.type.match(/drop/)) {
      return this.dragCoordinates;
    }
    return [event.pageX, event.pageY];
  }
  searchValidDropTarget(event) {
    let coord = this.coordFromEvent(event);
    let dropTargetElements = document.elementsFromPoint(coord[0], coord[1]);

    for (var i = 0; i < dropTargetElements.length; i++) {
      let testElement = this.getParent(
        dropTargetElements[i],
        "cardid",
        "dataset",
      );
      if (
        testElement &&
        testElement.dataset.cardid != this.dragItem.dataset.cardid
      ) {
        return testElement;
      }
    }
    return false;
  }

  /*
   *
   * Touch stuff
   *
   */

  touchMoveCard(event) {
    event.preventDefault();

    let draggedCard = this.getParent(event.target, "cardid", "dataset");
    let isTopTarget = this.isTopTargetEvent(event, draggedCard);
    let coord = this.coordFromEvent(event);

    var card_id = draggedCard.dataset["cardid"];

    this.dragItem = draggedCard;
    this.formatDragCard(this.dragItem);
    this.moveDragTwin(event);
    this.highlightCardDragOver(event);
  }
}
