import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */

  static targets = ["board", "addform", "bodyTextarea"];

  connect() {
    StimulusReflex.register(this);
    this.element[this.identifier] = this;

    /*
    console.log("boardsstimulus_controller.js says hi")
    console.log("checking websocket connection:")
    if(this.context.application.consumer.connection.disconnected) {
      console.log("\tnot connected")
    } else {
      console.log("\tconnected")
    }*/
  }

  editBoard(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    event.preventDefault(), event.stopPropagation();

    this.clearModalContent();

    console.log("event.target.dataset: " + event.target.dataset["boardId"]);

    if (!event.target.dataset["boardid"]) {
      var etarget = this.getParent(event.target, "button", "tagname");
    } else {
      var etarget = event.target;
    }

    console.log("etarget.dataset: " + Object.keys(etarget.dataset));

    var modalController = document.querySelector("#defaultModal").modal;

    modalController.show(event);
    modalController.hideFooter();

    this.stimulate("BoardsReflex#editBoard", etarget);
  }
  addColumn(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    this.stimulate(
      "BoardsReflex#addColumn",
      this.getParent(event.target, "a", "tagname"),
    );
  }
  async deleteColumn(event) {
    // if(!super.isConnected(event)) { return false; }

    const ok = confirm("Are you sure you would like to delete this column?");
    if (ok) {
      if (super.isConnected(event)) {
        this.stimulate(
          "BoardsReflex#deleteColumn",
          this.getParent(event.target, "button", "tagname"),
        );
      } else {
        let btn = super.getParent(event.target, "columnid", "dataset");
        const response = await this.doFetch(
          "/board_column/" + btn.dataset.columnid,
          "DELETE",
          JSON.stringify({
            authenticity_token: document.querySelector(
              'meta[name="csrf-token"]',
            ).content,
            remote: true,
          }),
        );
        if (response.ok) {
          location.reload();
        }
      }
    }
  }
  saveColumn(event) {
    event.preventDefault();
    event.stopPropagation();

    const title = document.querySelector("#board_column_title").value;
    const colstatus = document.querySelector("#board_column_status").value;
    const colcolor = document.querySelector("#board_column_color").value;

    this.stimulate(
      "BoardsReflex#saveColumn",
      this.getParent(event.target, "btn"),
      title,
      colstatus,
      colcolor,
    );
    this.getModalController().close();
  }

  getMde() {
    return document
      .querySelector('[data-controller="easymde"]')
      .easymde.getMde();
  }

  reflexSuccess(element, reflex, noop, reflexId) {
    if (reflex.match(/saveCard$/)) {
      this.getModalController().close();
    }
  }

  async saveCard(event) {
    event.preventDefault();
    event.stopPropagation();

    let columnid = event.target.dataset["columnid"];
    let postType = event.target.dataset["postType"];
    let form = document.querySelector('form[action*="posts"]');

    let stylePaletteId = document.querySelector(
      'select[id*="style_palette_id"',
    ).value;

    if (postType == "Post") {
      if (super.isConnected(event)) {
        this.stimulate(
          "BoardsReflex#saveCard",
          event.target,
          columnid,
          postType,
          this.getMde().value(),
          stylePaletteId,
        );
      } else {
        form.submit();
      }
      return true;
    }

    if (postType == "BookmarkPost") {
      if (super.isConnected(event)) {
        let urlValue = { url: document.querySelector("#post_url").value };
        console.log("urlValue: " + urlValue);
        this.stimulate(
          "BoardsReflex#saveCard",
          event.target,
          columnid,
          postType,
          "",
          stylePaletteId,
          urlValue,
        );
        return true;
      } else {
        form.submit();
      }
    }

    if (postType == "ImagePost") {
      // submit the form in a regular way to the ImagePostsController
      form.submit();
      return true;
    }
  }

  async deleteCard(event) {
    event.preventDefault();
    event.stopPropagation();

    const ok = confirm("Are you sure you would like to delete this card?");
    if (ok) {
      if (super.isConnected(event)) {
        this.stimulate(
          "BoardsReflex#deleteCard",
          this.getParent(event.target, "dropdown-item"),
        );
      } else {
        let btn = this.getParent(event.target, "button", "tagname");
        const response = await this.doFetch(
          "/posts/" + btn.dataset.cardid,
          "DELETE",
          JSON.stringify({
            authenticity_token: this.getCsrfToken(),
            remote: true,
          }),
        );
        if (response.ok) {
          location.reload();
        }
      }
    }
  }

  async deleteImagePostImage(event) {
    event.preventDefault();
    event.stopPropagation();

    const ok = confirm("Are you sure you would like to delete this image?");

    if (ok) {
      if (super.isConnected(event)) {
        this.stimulate(
          "BoardsReflex#deleteImagePostImage",
          this.getParent(event.target, "btn"),
        );
      } else {
        let btn = this.getParent(event.target, "button", "tagname");
        let imgli = this.getParent(btn, "li", "tagname");

        const response = await this.doFetch(
          "/image_posts/" +
            btn.dataset.cardid +
            "/deleteimage/" +
            btn.dataset.attachmentid,
          "DELETE",
          JSON.stringify({
            authenticity_token: document.querySelector(
              'meta[name="csrf-token"]',
            ).content,
            remote: true,
          }),
        );
        if (response.ok) {
          imgli.remove();
        }
      }
    }
  }

  duplicateCard(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    let card = this.getParent(event.target, "cardid", "dataset");
    this.stimulate("BoardsReflex#duplicateCard", card);
  }
  editColumn(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    this.clearModalContent();

    if (!event.target.dataset["columnid"]) {
      var etarget = this.getParent(event.target, "btn");
    } else {
      var etarget = event.target;
    }

    var modalController = document.querySelector("#defaultModal").modal;

    modalController.show(event);
    modalController.hideFooter();

    this.stimulate("BoardsReflex#editColumn", etarget);
  }

  addCard(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    this.clearModalContent();

    var etarget = this.getParent(event.target, "columnid", "dataset");
    this.showCardModal(event, etarget);
    this.stimulate("BoardsReflex#addCard", etarget);
  }
  batchAddCards(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    this.clearModalContent();

    var etarget = this.getParent(event.target, "columnid", "dataset");
    this.showCardModal(event, etarget);
    this.stimulate("BoardsReflex#batchAddCards", etarget);
  }
  editCard(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    this.clearModalContent();

    var etarget = this.getParent(event.target, "cardid", "dataset");
    if (this.context.application.consumer.connection.disconnected) {
      console.log("\tnot connected");
    } else {
      console.log("\tis connected");
    }

    this.showCardModal(event, etarget);
    this.stimulate("BoardsReflex#editCard", etarget);
  }
  afterReflex(anchorElement) {
    this.hideAllFloatedElements();
    if (anchorElement == undefined) {
      return false;
    }
    const action = anchorElement.dataset["action"];
    this.setLinkDefaultTarget();
  }
  setLinkDefaultTarget() {
    this.element.querySelectorAll(".card-body a").forEach(function (linkItem) {
      linkItem.setAttribute("target", "_blank");
    });
  }
  collapseCards(event) {
    document.querySelectorAll(".card-body").forEach(function (cardItem) {
      cardItem.classList.toggle("card-collapsed");
    });
  }
  showCardModal(event, etarget) {
    this.getModalController().show(event);
    this.getModalController().hideFooter();
  }
  showBookmarkCardModal(event, etarget) {
    this.getModalController().show(event);
    this.getModalController().hideFooter();

    this.stimulate("BoardsReflex#editBookmarkCard", etarget);
  }

  hideAddCardForms(event) {
    this.getModalController().close();
  }

  like(event) {
    let card = this.getParent(event.target, "cardid", "dataset");
    this.stimulate("BoardsReflex#like", card);
  }
  toggleHeaderSpans(headerSpans, tclass = "d-none") {
    for (var i = 0; i < headerSpans.length; i++) {
      headerSpans[i].classList.toggle(tclass);
    }
  }

  showBoardUsers() {
    if (!super.isConnected(event)) {
      return false;
    }

    this.clearModalContent();

    if (!event.target.dataset["board-id"]) {
      var etarget = this.getParent(event.target, "btn");
    } else {
      var etarget = event.target;
    }

    var modalController = document.querySelector("#defaultModal").modal;

    modalController.show(event);
    modalController.hideFooter();

    this.stimulate("BoardsReflex#showBoardUsers", etarget);
  }

  showStylesDropdown(event) {
    event.preventDefault();

    let btn = this.getParent(event.target, "button", "tagname");
    let sharedDropdown = document.querySelector("#board-styles-dropdown");
    var showMenu = !sharedDropdown.classList.contains("show");

    this.hideAllDropdowns();
    if (!showMenu) {
      return true;
    }

    sharedDropdown.dataset["stylableclass"] = btn.dataset["stylableclass"];
    sharedDropdown.dataset["stylableid"] = btn.dataset["stylableid"];
    sharedDropdown.classList.toggle("show");

    let viewportOffset = btn.getBoundingClientRect();
    let ytop = viewportOffset.top;
    let xleft = viewportOffset.left;

    sharedDropdown.style.top =
      window.scrollY + viewportOffset.top - viewportOffset.height + "px";
    sharedDropdown.style.left =
      window.scrollX + viewportOffset.left - viewportOffset.width + "px";
  }
}
