import { Controller } from "@hotwired/stimulus";
import StimulusReflex from "stimulus_reflex";
import EasyMDE from "easymde";

import { install } from "@github/hotkey";

// Install all the hotkeys on the page
for (const el of document.querySelectorAll("[data-hotkey]")) {
  install(el);
}

// import 'easymde/dist/easymde.min.css'

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    // StimulusReflex.register(this)
    this.styleBodyDisconnected();
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  isConnected(event) {
    let ws_meta_tag = document.querySelector(
      'meta[name="websocket_force_disabled"]',
    );
    if (ws_meta_tag !== undefined && ws_meta_tag.content == 1) {
      this.connectionState = false;
      this.styleBodyDisconnected();
    } else {
      this.connectionState =
        !this.context.application.consumer.connection.disconnected;
    }

    if (event != undefined && this.connectionState) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log(
      "websocket is " + (this.connectionState ? "connected" : "disconnected"),
    );
    return this.connectionState;
  }

  styleBodyDisconnected() {
    if (
      document.querySelector('meta[name="websocket_force_disabled"]').content ==
      1
    ) {
      document.querySelector("body").classList.add("wssdisconnected");
    } else {
      document.querySelector("body").classList.remove("wssdisconnected");
    }
  }

  beforeReflex(element, reflex, noop, reflexId) {}

  reflexSuccess(element, reflex, noop, reflexId) {}

  reflexError(element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted(element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  afterReflex(element, reflex, noop, reflexId) {}

  finalizeReflex(element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }

  getFlashController() {
    if (this.flashController == undefined) {
      this.flashController = document.querySelector("#flash-alert").flash;
    }
    return this.flashController;
  }

  getModalController() {
    if (this.modalController == undefined) {
      this.modalController = document.querySelector("#defaultModal").modal;
    }
    return this.modalController;
  }
  clearModalContent() {
    this.getModalController().clearContent();
    this.getModalController().close();
  }

  hideAllFloatedElements() {
    this.hideAllTooltips();
    this.hideAllDropdowns();
  }
  hideAllTooltips() {
    this.fadeOut(document.querySelector(".bs-tooltip-auto"));
  }
  hideAllDropdowns() {
    document.querySelectorAll(".dropdown-menu").forEach(
      function (menuItem) {
        if (menuItem !== this.menuTarget) {
          menuItem.classList.remove("show");
        }
      }.bind(this),
    );
  }

  getParent(tester, test_criterium, test_class = "class", test_value) {
    while (tester != null) {
      if (test_class == "tagname") {
        // console.log("test tagname: " + tester.tagName.toUpperCase())
        if (tester.tagName == test_criterium.toUpperCase()) {
          return tester;
        }
      }
      if (test_class == "class") {
        if (
          tester.classList != null &&
          tester.classList.contains(test_criterium)
        ) {
          return tester;
        }
      }
      if (test_class == "dataset") {
        if (
          test_criterium in tester.dataset === true &&
          test_value == undefined
        ) {
          return tester;
        }
        if (
          test_criterium in tester.dataset === true &&
          test_value == tester.dataset[test_criterium]
        ) {
          return tester;
        }
      }
      tester = tester.parentElement;
    }
    return false;
  }

  addClasses(trg, classesArray) {
    for (var i = 0; i < classesArray.length; i++) {
      trg.classList.add(classesArray[i]);
    }
  }
  removeClasses(trg, classesArray) {
    for (var i = 0; i < classesArray.length; i++) {
      trg.classList.remove(classesArray[i]);
    }
  }

  fadeOut(element, duration = 20) {
    if (element == undefined) {
      return false;
    }
    var op = 1;
    clearInterval(self.timer);
    self.timer = setInterval(function () {
      if (op <= 0.1) {
        clearInterval(self.timer);
        element.style.display = "none";
      }
      element.style.opacity = op;
      element.style.filter = "alpha(opacity=" + op * 100 + ")";
      op -= op * 0.1;
    }, duration);
  }

  fadeIn(element, duration = 20) {
    if (element == undefined) {
      return false;
    }
    var op = 0;
    clearInterval(self.timer);
    element.style.display = "block";
    self.timer = setInterval(function () {
      if (op >= 1) {
        clearInterval(self.timer);
      }
      element.style.opacity = op;
      element.style.filter = "alpha(opacity=" + op * 100 + ")";
      op += 0.1;
    }, duration);
  }
  async doFetch(url, method, body, headers = false) {
    if (!headers) {
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        authenticity_token: this.getCsrfToken(),
      };
    }
    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: body,
    });
    return response;
  }

  setCookie(cname, cvalue, exdays = 14) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }
  convertStringToHTML(str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");
    return doc.body;
  }
  getCsrfToken() {
    if (!document.querySelector('meta[name="csrf-token"]')) {
      return "";
    }
    if (document.querySelector('meta[name="csrf-token"]').content) {
      return document.querySelector('meta[name="csrf-token"]').content;
    }
    return "";
  }
}
