import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';


/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "preview", "input" ]
  static values = {
    paletteid: Number
  }

  connect () {
    StimulusReflex.register(this)
  }

  deletePalette (event) {
    alert("deletePalette")
  }
  
  async changedTitle (event) {
    if(!super.isConnected(event)) { 
      const response = await this.doFetch (
        '/style/',
        'PATCH',
        JSON.stringify({
          authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
          title: event.target.value,
          paletteid: this.element.dataset.stylepaletteseditorPaletteidValue
        }),
      );
      return true;
    }
    this.stimulate('StylepaletteEditorReflex#changedTitle', this.element, event.target.value)
  }
  changedProperty (event) {
    this.previewTarget.style[event.target.dataset['cssproperty']] = event.target.value

    if(!super.isConnected(event)) { 
      alert('changedProperty');
      return true;
    }


    this.stimulate('StylepaletteEditorReflex#changedProperty', event.target)
  }
}
